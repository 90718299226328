@import "~@/styles/variables/variables.scss";








































































































::v-deep .el-textarea__inner {
	height: 64px;
}
