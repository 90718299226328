@import "~@/styles/variables/variables.scss";














































































































































.header {
	@include base-button($width: 120px);
}
.page-main {
	flex-direction: column;
	padding: 20px 24px;
}
.table {
	margin-top: 22px;
}
::v-deep .ops-btn {
	cursor: pointer;
	color: $base-color-theme;
	+ .ops-btn {
		margin-left: 22px;
		color: #ff6a4d;
	}
}
